import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Buefy from "buefy";
//import { Button, Navbar, Progress, Steps } from "buefy";
import "animate.css";

Vue.use(Buefy);
//Vue.use(Button);
//Vue.use(Navbar);
//Vue.use(Progress);
//Vue.use(Steps);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
