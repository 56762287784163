<template>
  <div id="app">
    <navbar />
    <router-view />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "App",
  components: {
    Navbar,
  },
};
</script>

<style lang="scss">
.Site {
  display: flex;
  min-height: calc(100vh - 5.5rem);
  flex-direction: column;
}

.Site-content {
  flex: 1;
}

.hero-body {
  flex-wrap: wrap;
  align-content: center;
}
</style>
