<template>
  <b-navbar type="is-primary" wrapper-class="container" transparent fixed-top>
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <!--<logo />-->
        <p>
          <span class="brandname">TeStUp</span>
          <!--<span class="claim">Realisiere deine Idee</span>-->
        </p>
      </b-navbar-item>
    </template>

    <template slot="start"></template>

    <template slot="end">
      <b-navbar-item tag="router-link" :to="{ path: '/TechWednesday' }">
        TechWednesday
      </b-navbar-item>
      <b-navbar-item
        tag="a"
        href="https://hs-flensburg.de/go/TeStUp"
        target="_blank"
      >
        mehr über TeStUp
      </b-navbar-item>
      <b-navbar-item
        tag="a"
        href="https://hs-flensburg.de/datenschutzerklaerung"
        target="_blank"
      >
        Datenschutz
      </b-navbar-item>
      <b-navbar-item
        tag="a"
        href="https://hs-flensburg.de/impressum"
        target="_blank"
      >
        Impressum
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<style lang="scss">
.brandname {
  font-weight: 900;
}
.claim {
  font-weight: 400;
  font-size: 0.9rem;
  margin-left: 10px;
}
html.has-navbar-fixed-top,
body.has-navbar-fixed-top {
  padding-top: 0rem !important;
}

a.navbar-item,
a.navbar-link {
  font-weight: 700;
  font-size: 1.2rem;
  padding: 0.75rem 0.75rem;
  margin: 0rem 0.75rem;
  svg {
    max-height: 4rem;
    height: 4rem;
  }
}
a.navbar-item:hover svg path {
  fill: $white-ter;
}

a.navbar-link:not(.is-arrowless) {
  padding-right: 1.75em;
}
a.navbar-link:not(.is-arrowless)::after {
  margin-top: -0.5em;
  right: 0.75em;
  font-size: 0.9rem;
}
.navbar.is-primary .navbar-end > a.navbar-item.is-active,
.navbar.is-primary .navbar-end > a.navbar-item:hover,
.navbar.is-primary .navbar-end > .navbar-item a.navbar-link:hover {
  border-bottom: 0px solid $white-ter;
  color: $info !important;
}

.navbar-brand > a.navbar-item,
.navbar-brand > a.navbar-item.is-active,
.navbar-burger:hover,
.navbar-item.has-dropdown:hover .navbar-link {
  background-color: transparent !important;
}

.navbtn {
  margin-left: 1rem;
  font-size: 1.2rem;
}
@media screen and (max-width: $desktop) {
  .navbtn {
    margin-left: 0.75rem;
    margin-bottom: 1.5rem !important;
  }
  .navbar.is-primary .navbar-end > a.navbar-item.is-active,
  .navbar.is-primary .navbar-end > a.navbar-item:hover,
  .navbar.is-primary .navbar-end > .navbar-item a.navbar-link:hover {
    border-bottom: 0px solid $white;
    color: $info !important;
  }
}
</style>
