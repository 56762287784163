<template>
  <div class="container">
    <section class="hero is-fullheight is-primary">
      <div class="hero-body">
        <div class="coloumns is-centered">
          <div class="column is-full">
            <p class="title">Realisiere deine Idee an der HS Flensburg!</p>
            <p class="subtitle">
              Wir arbeiten gerade an dem Inhalt der Seite - mehr Infos gibt es
              solange hier:
            </p>
            <div class="buttons">
              <b-button
                type="is-primary"
                tag="a"
                class="is-medium"
                href="https://hs-flensburg.de/hochschule/einrichtung/testup-das-startup-village-fuer-technologische-produkte-der-hochschule"
                target="_blank"
                inverted
              >
                mehr über TeStUp
              </b-button>
              <b-button
                type="is-primary"
                tag="a"
                class="is-medium"
                href="https://hs-flensburg.de/forschung/fue/forschungsprojekte/techstartuphs-flensburg-testup"
                target="_blank"
                inverted
                outlined
              >
                mehr über das Forschungsvorhaben
              </b-button>
              <b-button
                type="is-primary"
                tag="a"
                class="is-medium"
                href="https://hs-flensburg.de"
                target="_blank"
                inverted
                outlined
              >
                zur HS Flensburg
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
